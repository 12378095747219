import styled from "styled-components";

import vars from "variables";

import down from "assets/Layout/Dashboard/Sidebar/down.svg";
import up from "assets/Layout/Dashboard/Sidebar/up.svg";
import link from "assets/Dashboard/link.svg";

const Root = styled.div`
  & {
    color: white;
    background: ${vars.colors.marineBlue};
    border-radius: 6px;
  }

  .progress {
    margin-right: 50px;
    margin-bottom: 7px;
    height: 4px;
    background: rgb(42, 44, 72);
    border-radius: 7px;
  }

  .progress div {
    height: 4px;
    background: ${vars.colors.blue};
    border-radius: 7px;
  }

  .pagination {
    margin-top: 25px;
    margin-bottom: -50px;
    font-size: 0.95rem;
  }

  .pagination a {
    font-weight: normal;
  }

  .pagination a.paginator-next {
    opacity: 0.5;
    border-width: 1px;
  }

  .tooltip {
    top: 40px;
    left: -50px;
    margin-top: 20px;
    width: 500px;
    padding: 20px 24px;
    display: none;
    line-height: 175%;
    position: absolute;
    background-color: ${vars.colors.background};
    border-radius: 12px;
    opacity: 1 !important;
    z-index: 1;
    &:before {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid ${vars.colors.background};
      border-left: 8px solid transparent;
      top: -16px;
      left: 72px;
    }
  }

  td:hover .tooltip {
    display: inline-block;
  }

  ${vars.desktop.mediaQuery} {
    & {
      font-weight: bold;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    .list {
      display: none;
    }

    & > div {
      padding: 28px 45px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th {
      padding: 12px 0;
      font-size: 0.7rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: left;
    }

    tbody tr {
      border-top: 1px solid ${vars.colors.background};
    }

    td {
      padding: 12px 0;
      color: #ccc;
      font-size: 0.95rem;
      font-weight: 400;
      text-transform: none;
      position: relative;
    }

    td.blue {
      color: ${vars.colors.blue};
    }

    td.grey,
    td.grey a {
      color: #979797;
    }

    td a:hover {
      color: ${vars.colors.blue};
    }
  }

  ${vars.mobile.mediaQuery} {
    .table {
      display: none;
    }

    ul {
      padding: 0 20px;
    }

    li {
      padding: 20px 0;
      font-size: 0.85rem;
      border-bottom: 1px solid ${vars.colors.background};
    }

    li:last-child {
      border: 0;
    }

    li span {
      color: white;
    }

    li.open a,
    li.open p {
      display: block;
    }

    span.blue {
      color: ${vars.colors.blue};
    }

    .flex {
      margin-top: 10px;
      margin-bottom: 2px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .progress {
      width: 125px;
      height: 6px;
      margin-top: 5px;
      margin-left: 10px;
      margin-right: 21px;
    }

    .progress div {
      height: 6px;
    }

    h4 {
      padding-right: 21px;
      display: flex;
      font-weight: normal;
      font-size: 1rem;
      justify-content: space-between;
      background: url(${down}) no-repeat right center;
    }

    li.open h4 {
      background-image: url(${up});
    }

    a {
      margin-top: 7px;
      margin-right: 22px;
      padding-right: 26px;
      color: #979797;
      display: none;
      text-align: right;
      background: url(${link}) no-repeat right center;
    }

    p {
      padding: 20px 21px 3px;
      display: none;
      text-align: justify;
    }
  }
`;

export { Root };
