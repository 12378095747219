import React, { useEffect, useState } from "react";
import { Root } from "./style";

import Paginator from "components/Common/Paginator";
import Layout from "components/Layout/Dashboard";

import { formatNumber, inWindow, useSiteData } from "utils";
import { useDashboard } from "dashboard";

import vars from "variables";

import link from "assets/Dashboard/link.svg";

const DashboardHodlers = ({ location }) => {
  const headers = ["Rank", "Address", "Balance", "Percentage", "BscScan"];
  const widths = ["100px", "", "235px", "250px", "80px"];

  const { addresses } = useDashboard();

  const { projectWallets } = useSiteData().resources;

  const publicTags = projectWallets.reduce((tags, { address, name }) => {
    tags[address] = name;
    return tags;
  }, {});

  const walletDescriptions = projectWallets.reduce(
    (descriptions, { address, description }) => {
      descriptions[address] = description;
      return descriptions;
    },
    {}
  );

  const [holders, setHolders] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchHolders = (page) => {
    fetch(`${vars.apiHost}/v2/-/position/0`)
      .then((response) => response.json())
      .then((data) => setTotal(data.total));
    fetch(`${vars.apiHost}/v2/-/holders/${page || 1}`)
      .then((response) => response.json())
      .then(setHolders);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    pageParam && setPage(parseInt(pageParam));
    fetchHolders(parseInt(pageParam));
  }, []);

  return (
    <Layout location={location}>
      {inWindow() ? (
        <Root>
          <div className="table">
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => {
                    return (
                      <th key={index} style={{ width: widths[index] }}>
                        {header}
                      </th>
                    );
                  })}
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {holders.map(([address, balance, percentage], index) => {
                  return (
                    <tr key={`row${index}`}>
                      <td>{(page - 1) * 25 + index + 1}</td>
                      <td
                        className={
                          Object.entries(addresses.get()).find(
                            ([a, selected]) => selected && a === address
                          )
                            ? "blue"
                            : "grey"
                        }
                      >
                        {publicTags[address] || address}
                        {walletDescriptions[address] ? (
                          <div className="tooltip">
                            {walletDescriptions[address]}
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{formatNumber(balance, 0)}</td>
                      <td>
                        <div>{formatNumber(percentage, 4)}%</div>
                        <div className="progress">
                          <div style={{ width: `${percentage}%` }}></div>
                        </div>
                      </td>
                      <td className="grey">
                        <a
                          target="blank"
                          href={`https://bscscan.com/address/${address}`}
                        >
                          <img alt="bscscan" src={link} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Paginator
              path={location.pathname}
              perPage={25}
              pageIndex={page}
              paramName="page"
              totalCount={total}
            />
          </div>
          <div className="list">
            <ul>
              {holders.map(([address, balance, percentage], index) => {
                return (
                  <li
                    key={`item${index}`}
                    onClick={(e) =>
                      e.target.closest("li").classList.toggle("open")
                    }
                  >
                    <h4>
                      <span>{(page - 1) * 25 + index + 1}</span>
                      <span
                        className={
                          Object.entries(addresses.get()).find(
                            ([a, selected]) => selected && a === address
                          )
                            ? "blue"
                            : ""
                        }
                      >
                        {formatNumber(balance)}
                      </span>
                    </h4>
                    <div className="flex">
                      <label>{formatNumber(percentage, 4)}%</label>
                      <div className="progress">
                        <div style={{ width: `${percentage}%` }}></div>
                      </div>
                    </div>
                    <a
                      target="blank"
                      href={`https://bscscan.com/address/${address}`}
                    >
                      {publicTags[address]
                        ? publicTags[address]
                        : `${address.slice(0, 17)}...${address.slice(-17)}`}
                    </a>
                    {walletDescriptions[address] ? (
                      <p>{walletDescriptions[address]}</p>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Root>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default DashboardHodlers;
