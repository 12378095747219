import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  margin-top: -15px;
  padding-bottom: 85px;
  text-align: center;

  .paginator a {
    margin: 0 15px;
    font-weight: bold;
  }

  .paginator a.paginator-page {
    opacity: 0.5;
  }

  .paginator a.paginator-previous,
  .paginator a.paginator-next {
    padding: 10px 15px;
    color: #eee;
    font-size: 0.8rem;
    opacity: 0.9;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border: 2px solid #bbb;
    border-radius: 6px;
    background: #333544;
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
